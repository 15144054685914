@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --scrollbar-bg: #ffffff;
    --scrollbar-marker: #3d3957;
    --background-main: #F6F7FB;
    --font-poppins: 'Poppins', sans-serif;
    --font-lato: 'Lato', sans-serif;
    --font-nunito: 'Nunito', sans-serif;
}
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-marker) var(--scrollbar-bg);
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}
  
*::-webkit-scrollbar-track {
    background: var(--scrollbar-bg);
    border-radius: 5px;
}
  
*::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-marker);
    border-radius: 14px;
    border: 3px solid var(--scrollbar-bg);
}
body {
    background-color: var(--background-main);
    font-family: var(--font-poppins);
}
input {
    font-family: var(--font-lato);
    font-weight: 300;
}
.font-lato {
    font-family: var(--font-lato);
}
.font-nunito {
    font-family: var(--font-nunito);
}